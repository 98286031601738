import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["src", "alt"]

import { storeToRefs } from 'pinia'
import Button from '@/components_new/Button.vue'
import { useCategoriesStore } from '@/store/categories-store'
import TheEndImg from './images/the_end.svg'
import { useIsMounted } from '@/utils/vue-hooks/use-is-mounted'
import { ROUTE_NAMES } from '@/router/constants'


export default /*@__PURE__*/_defineComponent({
  __name: 'TheEnd',
  props: {
    targetUrl: {}
  },
  setup(__props: any) {

const props = __props
const categoriesStore = useCategoriesStore()

const { randomGameHRU } = storeToRefs(categoriesStore)
const isMounted = useIsMounted()

function handleClick() {
    categoriesStore.updateRandomGame()
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.$style.end)
  }, [
    (_unref(randomGameHRU) && _unref(isMounted))
      ? (_openBlock(), _createBlock(Button, {
          key: 0,
          interfaceTarget: "random-game",
          size: "l",
          width: "full",
          target: props.targetUrl,
          to: { name: _unref(ROUTE_NAMES).GAME_PAGE, params: { game: _unref(randomGameHRU) } },
          onClick: handleClick
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$tr('theEnd.feeling_lucky')), 1)
          ]),
          _: 1
        }, 8, ["target", "to"]))
      : (_openBlock(), _createBlock(Button, {
          key: 1,
          size: "l",
          interfaceTarget: "empty-random-game",
          width: "full"
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$tr('theEnd.feeling_lucky')), 1)
          ]),
          _: 1
        })),
    _createElementVNode("img", {
      src: _unref(TheEndImg),
      alt: _ctx.$tr('theEnd.end_alt'),
      class: _normalizeClass(_ctx.$style.pic),
      loading: "lazy"
    }, null, 10, _hoisted_1)
  ], 2))
}
}

})