<template>
    <div :class="$style.end">
        <Button
            v-if="randomGameHRU && isMounted"
            interfaceTarget="random-game"
            size="l"
            width="full"
            :target="props.targetUrl"
            :to="{ name: ROUTE_NAMES.GAME_PAGE, params: { game: randomGameHRU } }"
            @click="handleClick"
        >
            {{ $tr('theEnd.feeling_lucky') }}
        </Button>
        <Button
            v-else
            size="l"
            interfaceTarget="empty-random-game"
            width="full"
        >
            {{ $tr('theEnd.feeling_lucky') }}
        </Button>
        <img
            :src="TheEndImg"
            :alt="$tr('theEnd.end_alt')"
            :class="$style.pic"
            loading="lazy"
        />
    </div>
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia'
import Button from '@/components_new/Button.vue'
import { useCategoriesStore } from '@/store/categories-store'
import TheEndImg from './images/the_end.svg'
import { useIsMounted } from '@/utils/vue-hooks/use-is-mounted'
import { ROUTE_NAMES } from '@/router/constants'

const props = defineProps<{
    targetUrl?: '_blank'
}>()
const categoriesStore = useCategoriesStore()

const { randomGameHRU } = storeToRefs(categoriesStore)
const isMounted = useIsMounted()

function handleClick() {
    categoriesStore.updateRandomGame()
}
</script>

<style module>
.end {
    padding: 32px 0;
    width: 272px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    gap: 40px;
}

.pic {
    width: 142px;
    height: 64px;
    color: #9747ff;
    align-self: center;
    position: relative;
}
</style>
