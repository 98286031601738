<template>
    <div
        data-interface-section="promo-categories"
        :class="$style.promo"
    >
        <div :class="$style.texts">
            <Typography
                :is="props.hasHeader ? 'h1' : 'div'"
                type="header"
                size="xs"
                :responsive="false"
            >
                {{ $tr('promoCategories.title') }}
            </Typography>
            <div :class="$style.text">
                <Typography
                    type="paragraph"
                    size="xs"
                >
                    {{ $tr('promoCategories.text') }}
                </Typography>
            </div>
        </div>
        <div :class="$style.categories">
            <Button
                v-for="category in promoCategoriesList"
                :key="category.id"
                interfaceTarget="category"
                :to="category.location"
                color="carbon-400"
                :iconLeft="category.id"
            >
                {{ category.title }}
            </Button>
        </div>
        <img
            :src="fennekImg"
            :alt="$tr('promoCategories.img_alt')"
            :class="$style.fennek"
            loading="lazy"
        />
    </div>
</template>
<script lang="ts" setup>
import Button from '@/components_new/Button.vue'
import Typography from '@/components_new/Typography.vue'
import fennekImg from './images/fennek.webp'
import { useCategoriesStore } from '@/store/categories-store'
import { storeToRefs } from 'pinia'

const categoriesStore = useCategoriesStore()

const { promoCategoriesList } = storeToRefs(categoriesStore)

type PromoCategoriesProps = {
    hasHeader?: boolean
}

const props = defineProps<PromoCategoriesProps>()
</script>
<style module>
.promo {
    border-radius: 16px;
    background: #0d0d0f;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    gap: 24px;
    padding: 24px 24px 0;
}

.texts {
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.text {
    color: rgba(255, 255, 255, 0.8);
}

.categories {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 8px;
}

.fennek {
    display: block;
    width: 154px;
    height: 94px;
}
</style>
