import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, normalizeClass as _normalizeClass, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import CardContainer from '@/components_new/CardContainer.vue'
import BaseLink from '@/components_new/BaseLink.vue'
import { DEVELOPERS_LANDPAGE_PATH } from '@/router/navigation-constants'
import Icon from '@/components_new/Icon/Icon.vue'
import Typography from '@/components_new/Typography.vue'

interface AddGamePromoCardProps {
    appearance: 'big' | 'medium'
    responsive: boolean
}


export default /*@__PURE__*/_defineComponent({
  __name: 'AddGamePromoCard',
  props: {
    appearance: {},
    responsive: { type: Boolean }
  },
  setup(__props: any) {

/* eslint-disable */
const props = __props

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(CardContainer, {
    is: BaseLink,
    appearance: props.appearance,
    responsive: props.responsive,
    target: "_blank",
    to: _unref(DEVELOPERS_LANDPAGE_PATH),
    external: "",
    class: _normalizeClass(_ctx.$style.card)
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", {
        class: _normalizeClass(_ctx.$style.card_content)
      }, [
        _createVNode(Icon, {
          name: "plus",
          class: _normalizeClass(_ctx.$style.icon)
        }, null, 8, ["class"]),
        _createVNode(Icon, {
          name: "chevron_r",
          class: _normalizeClass(_ctx.$style.icon_active)
        }, null, 8, ["class"]),
        _createVNode(Typography, {
          type: "label",
          size: "l",
          accent: "",
          responsive: false
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$tr('addGamePromo.title')), 1)
          ]),
          _: 1
        }),
        _createVNode(Typography, {
          type: "label",
          size: "xs",
          responsive: false,
          class: _normalizeClass(_ctx.$style.description)
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$tr('addGamePromo.description')), 1)
          ]),
          _: 1
        }, 8, ["class"])
      ], 2)
    ]),
    _: 1
  }, 8, ["appearance", "responsive", "to", "class"]))
}
}

})