import { useCssVars as _useCssVars, unref as _unref, defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, renderList as _renderList, renderSlot as _renderSlot, createElementVNode as _createElementVNode } from "vue"

import { onMounted, ref, useCssModule } from 'vue'
import { useResizeObserver, useMutationObserver } from '@vueuse/core'
import Button from '@/components_new/Button.vue'
import Icon from '@/components_new/Icon/Icon.vue'
import Typography from '@/components_new/Typography.vue'
import BaseLink from '@/components_new/BaseLink.vue'
import { RouteLocationRaw } from 'vue-router'

interface GameCarouselProps {
    items: T[]
    dummy?: boolean
    title?: string
    link?: RouteLocationRaw
    rows?: number
    lastItemLink?: RouteLocationRaw
}


export default /*@__PURE__*/_defineComponent({
  __name: 'CardCarousel',
  props: {
    items: {},
    dummy: { type: Boolean },
    title: {},
    link: {},
    rows: { default: 1 },
    lastItemLink: {}
  },
  setup(__props: any) {

_useCssVars(_ctx => ({
  "55cf749d": (props.rows)
}))

const props = __props

const classes = useCssModule()

const scrollerRef = ref<HTMLElement | null>(null)
const leftArrowActive = ref(false)
const rightArrowActive = ref(false)

function onScroll(e: { currentTarget: unknown | null }) {
    const scroller = e.currentTarget
    if (!(scroller instanceof HTMLElement)) {
        return
    }
    const notEmpty = !props.dummy
    leftArrowActive.value = notEmpty && scroller.scrollLeft > 0
    rightArrowActive.value = notEmpty && scroller.scrollLeft + scroller.clientWidth + 1 < scroller.scrollWidth
}

function getScrollStepSize() {
    // get the width of the second card (first card sometimes could be big one)
    const card = scrollerRef.value?.querySelector(`.${classes.card}:nth-child(2)`)
    if (!card) {
        return 0
    }

    const computedStyle = getComputedStyle(card)
    const cardWidth = parseInt(computedStyle.getPropertyValue('width'), 10)
    let cardsCount = parseInt(computedStyle.getPropertyValue('--count'), 10)

    // if there are more than one big card, we assume that the carousel is "big" and every card takes 2 regular slots
    // for now don't check card_big_tablet_plus class with first big item for tablet+ screens - left scrolling by small steps
    const hasBigCards = scrollerRef.value?.querySelectorAll(`.${classes.card_big}`)
    if (hasBigCards && hasBigCards.length > 1) {
        cardsCount /= 2
    }
    const gap = parseInt(computedStyle.getPropertyValue('--gap'), 10)

    return cardsCount * cardWidth + (cardsCount - 1) * gap
}

function onArrowLeftClick() {
    const scroller = scrollerRef.value
    if (scroller) {
        const scrollSize = getScrollStepSize()
        scroller.scroll({
            left: Math.max(0, scroller.scrollLeft - scrollSize),
            behavior: 'smooth',
        })
    }
}
function onArrowRightClick() {
    const scroller = scrollerRef.value
    if (scroller) {
        const scrollSize = getScrollStepSize()
        scroller.scroll({
            left: Math.min(scroller.scrollWidth - scroller.clientWidth, scroller.scrollLeft + scrollSize),
            behavior: 'smooth',
        })
    }
}

useResizeObserver(scrollerRef, () => {
    if (scrollerRef.value) {
        onScroll({ currentTarget: scrollerRef.value })
    }
})

useMutationObserver(
    scrollerRef,
    () => {
        if (scrollerRef.value) {
            onScroll({ currentTarget: scrollerRef.value })
        }
    },
    {
        childList: true,
    },
)

onMounted(() => {
    if (scrollerRef.value) {
        onScroll({ currentTarget: scrollerRef.value })
    }
})

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.$style.container)
  }, [
    (props.link)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
          (props.title)
            ? (_openBlock(), _createBlock(Typography, {
                key: 0,
                is: "h2",
                size: "xs",
                responsive: false,
                class: _normalizeClass(_ctx.$style.title),
                "data-interface-target": "navigation-link"
              }, {
                default: _withCtx(() => [
                  _createVNode(BaseLink, {
                    to: props.link,
                    tabindex: "-1",
                    color: "white"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(props.title), 1)
                    ]),
                    _: 1
                  }, 8, ["to"]),
                  _createVNode(Button, {
                    icon: "chevron_r",
                    color: "carbon-400",
                    size: "s",
                    to: props.link,
                    interfaceTarget: "navigation-link",
                    class: _normalizeClass(_ctx.$style.title_button)
                  }, null, 8, ["to", "class"])
                ]),
                _: 1
              }, 8, ["class"]))
            : _createCommentVNode("", true)
        ], 64))
      : (props.title)
        ? (_openBlock(), _createBlock(Typography, {
            key: 1,
            is: "h2",
            size: "xs",
            responsive: false,
            class: _normalizeClass(_ctx.$style.title)
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(props.title), 1)
            ]),
            _: 1
          }, 8, ["class"]))
        : _createCommentVNode("", true),
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.$style.scroller_wrap)
    }, [
      _createElementVNode("div", {
        ref_key: "scrollerRef",
        ref: scrollerRef,
        class: _normalizeClass({
                    [_ctx.$style.scroller]: true,
                    [_ctx.$style.scroller_empty]: props.dummy,
                }),
        onScroll: onScroll
      }, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(props.items, (item, index) => {
          return _renderSlot(_ctx.$slots, "default", {
            key: index,
            item: item,
            index: index,
            className: _ctx.$style.card,
            bigCardClassName: _ctx.$style.card_big,
            bigCardTabletPlusClassName: _ctx.$style.card_big_tablet_plus
          })
        }), 128)),
        (props.lastItemLink)
          ? (_openBlock(), _createBlock(Button, {
              key: 0,
              icon: "chevron_r",
              color: "carbon-400",
              interfaceTarget: "last-carousel-item",
              size: "l",
              to: props.lastItemLink,
              class: _normalizeClass([_ctx.$style.card, _ctx.$style.card_next])
            }, null, 8, ["to", "class"]))
          : _createCommentVNode("", true)
      ], 34),
      _createElementVNode("button", {
        tabindex: "-1",
        class: _normalizeClass([_ctx.$style.arrow, leftArrowActive.value && _ctx.$style.arrow_active, _ctx.$style.arrow_left]),
        onClick: onArrowLeftClick
      }, [
        _createVNode(Icon, { name: "chevron_l" })
      ], 2),
      _createElementVNode("button", {
        tabindex: "-1",
        class: _normalizeClass([_ctx.$style.arrow, rightArrowActive.value && _ctx.$style.arrow_active, _ctx.$style.arrow_right]),
        onClick: onArrowRightClick
      }, [
        _createVNode(Icon, { name: "chevron_r" })
      ], 2)
    ], 2)
  ], 2))
}
}

})